import React from 'react';
const TrustboxMicroCombo = () => {

  const ref = React.useRef(null);
  React.useEffect(() => {

if (window.Trustpilot) {
  window.Trustpilot.loadFromElement(ref.current, true);
}
  }, []);
  return (
    <div
       ref={ref}
       className="trustpilot-widget"
       data-locale="nl-NL"
       data-template-id="5419b6ffb0d04a076446a9af"
       data-businessunit-id="58fe12490000ff0005a11d05"
       data-style-height="20px"
       data-style-width="100%"
       data-theme="light"
       data-text-color="##27283C"
      >
      <a href="https://nl.trustpilot.com/review/employes.nl" target="_blank" rel="noopener"> Trustpilot</a>
    </div>
  );
};

export default TrustboxMicroCombo;
