import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const DelayBeforeHidingOverlay = 1000

class Overlay extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    className: PropTypes.string,
    onShow: PropTypes.func,
    onClose: PropTypes.func,
  }

  static defaultProps = {
    isOpen: false,
    showCloseButton: false,
    className: '',
  }

  container = React.createRef()

  componentDidUpdate(prevProps) {
    const { isOpen: isOpenCurrent } = this.props
    const { isOpen: isOpenPrevious } = prevProps

    if (isOpenCurrent === true && isOpenPrevious === false) {
      setTimeout(() => {
        const { onShow } = this.props
        onShow && onShow()
      }, 600)
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleEscapeKeyDown, false)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleEscapeKeyDown, false)
  }

  get classes() {
    const { isOpen, showCloseButton, className } = this.props

    return classnames('overlay', className, {
      'visible': isOpen,
      'with-close-button': showCloseButton,
    })
  }

  closeOverlay = () => {
    this.container.current.classList.add('closing')

    const { onClose, onClosing } = this.props
    onClosing && onClosing()

    setTimeout(() => {
      onClose && onClose()
    }, DelayBeforeHidingOverlay)
  }

  handleClose = () => {
    this.closeOverlay()
  }

  handleEscapeKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.closeOverlay()
    }
  }

  render() {
    const { showCloseButton, children, onClose } = this.props

    return (
      <div ref={this.container} className={this.classes}>
        {
          showCloseButton &&
          <button title="Close" className="overlay-close-button" onClick={this.handleClose} />
        }

        {children}
      </div>
    )
  }
}

export default Overlay