import React from 'react'
import PropTypes from 'prop-types'
import { withPrefix } from 'gatsby'
import { screenshot_lp } from 'images'
import config from '../config'
const { appUrl, signupRoute } = config

class LandingPageLowLevel extends React.Component {
  render() {
    return (<section className="complete-low-level padding-l">
      <div className="container-sm">
        <div className="grid center text-center margin-m-bottom">
          <div className="col-12">
            <p className="eyebrow">Eenvoud en gemak</p>
            <h2>Speciaal voor ondernemers</h2>
            <p className="streamer center">Onze software is speciaal voor ondernemers. Daardoor is het geen ingewikkelde software, maar een makkelijk systeem.</p>
          </div>
        </div>

        <div className="slides">
          <div className="grid text-center center screen video-container">
            <div className="col-12">
              <img src={screenshot_lp} alt="Illustration" ref="screenshot" />
            </div>
          </div>
        </div>

        <div className="grid yg center text-center margin-m-top">
          <div className="col-12">
            <a className="btn middle-button secondary lg margin-xs-bottom" href={appUrl} rel="nofollow">Probeer nu gratis</a>
            <p>Of neem contact op via tel. 020-808 5481.</p>
          </div>
        </div>

      </div>
    </section>)
  }
}

export default LandingPageLowLevel
